import * as grantObject from '../../Api/v2/Objects/GrantObject';
import * as organizationObject from '../../Api/v2/Objects/OrganizationObject';

const docLink = '/docs/changelog';

const idMap = {
  changelog: 'object',
  oct12024: 'October 1, 2024',
  aug32024: 'August 3, 2024',
  jul222024: 'July 22, 2024',
  mar102024: 'March 10, 2024',
  feb222024: 'Febraury 22, 2024',
  feb22024: 'Febraury 2, 2024',
  jan222024: 'January 22, 2024',
  jan112024: 'January 11, 2024',
  dec112024: 'December 11, 2024',
};

function getEndpointIds() {
  const ids = {};

  Object.entries(idMap).map(([key, value]) => {
    ids[value] = key;
    return key;
  });

  return ids;
}

const endpointIds = getEndpointIds();

const model =
{
  header: 'Changelog',
  object: endpointIds.object.id,
  link: `${docLink}#${endpointIds.object.id}`,
};

const endpoints = {
  [idMap.oct12024]: {
    title: idMap.oct12024,
    id: 'oct12024',
    link: `${docLink}#oct12024`,
    descriptions: [
      <>
        Deprecated funders, vendors, organizations, and user endpoints
        .
      </>,
    ],
  },
  [idMap.aug32024]: {
    title: idMap.aug32024,
    id: 'aug32024',
    link: `${docLink}#aug32024`,
    descriptions: [
      <>
        Deprecated &quot;filterFacets&quot; in favor of &quot;filters,&quot; which
        now allows filtering by numeric comparison when
        <span> </span>
        <a href={grantObject.endpoints.search.link}>searching grants</a>
        .
      </>,
    ],
  },
  [idMap.jul222024]: {
    title: idMap.jul222024,
    id: 'jul222024',
    link: `${docLink}#jul222024`,
    descriptions: [
      <>
        Add filtering for eligible activities when
        <span> </span>
        <a href={grantObject.endpoints.search.link}>searching grants</a>
      </>,
    ],
  },
  [idMap.mar102024]: {
    title: idMap.mar102024,
    id: 'mar102024',
    link: `${docLink}#mar102024`,
    descriptions: [
      <>
        Add new endpoint for retrieving and searching
        <span> </span>
        <a href={organizationObject.model.link}>grant seeking organizations</a>
      </>,
    ],
  },
  [idMap.feb222024]: {
    title: idMap.feb222024,
    id: 'feb222024',
    link: `${docLink}#feb222024`,
    descriptions: [
      'Include a matching percentage in the match object for both grants and funders, indicating the extent to which the post body aligns with a specific grant or funder - matchingPercentage.',
      'Instead of providing a consolidated list of the top 20 - 30 matches for both grants and funders, return separate lists for each, showcasing their respective top 20-30 matches.',
    ],
  },
  [idMap.feb22024]: {
    title: idMap.feb22024,
    id: 'feb22024',
    link: `${docLink}#feb22024`,
    descriptions: [
      'Add new filterable category for grants - otherCategory.',
    ],
  },
  [idMap.jan222024]: {
    title: idMap.jan222024,
    id: 'jan222024',
    link: `${docLink}#jan222024`,
    descriptions: [
      'Add support for searching and retrieving only state grants. By default, ["locations:{state}"] on its own, returns state and federal grants that specify the state. To exclusively search and retrieve state grants, use an add operation "inlcudeFederal:false" with a locations facet filter as shown in the example: ["locations:{state}","includeFederal:false"].',
    ],
  },
  [idMap.jan112024]: {
    title: idMap.jan112024,
    id: 'jan112024',
    link: `${docLink}#jan112024`,
    descriptions: [
      'Add support for filtering grants by match required - matchRequired.',
    ],
  },
  [idMap.dec112024]: {
    title: idMap.dec112024,
    id: 'dec112024',
    link: `${docLink}#dec112024`,
    descriptions: [
      'Add a new api endpoint.',
      'Deprecated version 1 of the api.',
    ],
  },
};

export {
  model,
  endpoints,
};
