import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SideNavigation from '../../Shared/SideNavigation';
import APIReference from './Components/APIReference';
import NotificationBanner from '../../../Banners/NotificationBanner';
import * as grantObject from './Objects/GrantObject';
import * as funderObject from './Objects/FunderObject';
import * as vendorObject from './Objects/VendorObject';
import * as matchObject from './Objects/MatchObject';
import * as organizationObject from './Objects/OrganizationObject';
import * as paginationObject from './Objects/PaginationObject';
import * as filterOptionObject from './Objects/FilterOptionObject';
import * as changeLogObject from '../../Changelog/Objects/ChangelogObject';
import * as userObject from './Objects/UserObject';
import apiReferenceObject from './Objects/ApiReferenceObject';
import ApiSection from './Shared/Sections/ApiSection';
import { apiFetchUnAuthenticated } from '../../../../../api/api';
import { BannerTypes } from '../../../../../constants/Banners';

const Doc = () => {
  const [objects, setObjects] = useState({
    apiReferenceObject: { object: { model: apiReferenceObject } },
    grantObject: { object: grantObject, examples: {} },
    funderObject: { object: funderObject, examples: {} },
    matchObject: { object: matchObject, examples: {} },
    vendorObject: { object: vendorObject, examples: {} },
    organizationObject: { object: organizationObject, examples: {} },
    userObject: { object: userObject, examples: {} },
    paginationObject: { object: paginationObject, examples: {} },
    filterOptionObject: { object: filterOptionObject, examples: {} },
  });
  const version = 'v2';
  const navigationMenu = [
    'grantObject',
    'matchObject',
    'paginationObject',
    'filterOptionObject',
  ];

  const init = async () => {
    if (window.location.hash) {
      window.location.href = `${window.location.origin}${window.location.pathname}${window.location.hash}`;
    }

    const exampleQueries = [
      apiFetchUnAuthenticated({ path: `/${version}/examples${grantObject.endpoints.get.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${grantObject.endpoints.getAll.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${grantObject.endpoints.search.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${matchObject.endpoints.getAll.example}` }),
      apiFetchUnAuthenticated({ path: `/v2/examples${filterOptionObject.endpoints.get.example}` }),
    ];

    const [
      getGrantExamples,
      getAllGrantExamples,
      searchGrantExamples,
      getAllMatchExamples,
      getFilterOptionExamples,
    ] = await Promise.all(exampleQueries);

    const newObjects = {
      ...objects,
      grantObject: {
        ...objects.grantObject,
        examples: {
          object: getGrantExamples.data,
          get: getGrantExamples,
          getAll: getAllGrantExamples,
          search: searchGrantExamples,
        },
      },
      matchObject: {
        ...objects.matchObject,
        examples: {
          object: getAllMatchExamples,
          getAll: getAllMatchExamples,
          generate: getAllMatchExamples,
        },
      },
      paginationObject: {
        ...objects.paginationObject,
        examples: {
          object: searchGrantExamples.data,
        },
      },
      filterOptionObject: {
        ...objects.filterOptionObject,
        examples: {
          object: getFilterOptionExamples,
          get: getFilterOptionExamples,
        },
      },
    };

    setObjects(newObjects);
  };

  const sideNavigation = Object.entries(objects).map(([key, object]) => {
    if (navigationMenu.includes(key)) { return object.object; }
    return null;
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <Row className="vw-100 vh-100 bg-white">
      <Col sm={3} className="p-48 h-100 overflow-scroll">
        <h2>OpenGrants API v2</h2>
        <SideNavigation objects={sideNavigation} />
        <hr />
        <SideNavigation
          objects={[{ model: changeLogObject.model }]}
        />
      </Col>
      <Col sm={9} className="p-48 h-100 overflow-scroll api-docs">
        <NotificationBanner
          type={BannerTypes.NOTIFICATION}
          title="Notification Alert"
          text={
            (
              <span>
                This is version 2 of the OpenGrants API.
                <span> </span>
                <a href="/docs/api/v1">Version 1</a>
                <span> </span>
                of our API (formerly hosted at
                <span> </span>
                <a href="https://app.swaggerhub.com/apis-docs/OpenGrants/og-api-prod-dev-portal/1.0.0" target="blank">Swagger Hub</a>
                ) is deprecated.
              </span>
            )
          }
        />
        <APIReference />
        <hr />
        {
          navigationMenu.map(menu => (
            <ApiSection
              key={menu}
              object={objects[menu].object}
              examples={objects[menu].examples}
            />
          ))
        }
      </Col>
    </Row>
  );
};

export default Doc;
